import type { Application, SPEObject } from "@splinetool/runtime";
import clsx from "clsx";
import {
  type PointerEvent as ReactPointerEvent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";

export default function RotatingLogo() {
  const [loaded, setLoaded] = useState(false);
  const [grabbing, setGrabbing] = useState(false);

  const canvasRef = useRef<HTMLCanvasElement>(null);

  const deltaRef = useRef(0);
  const onPointerDown = useCallback((e: ReactPointerEvent<HTMLDivElement>) => {
    setGrabbing(e.buttons !== 0);
  }, []);
  const onPointerMove = useCallback((e: ReactPointerEvent<HTMLDivElement>) => {
    if (e.buttons !== 0) deltaRef.current += e.movementX;
  }, []);
  const onPointerUp = useCallback((e: ReactPointerEvent<HTMLDivElement>) => {
    setGrabbing(e.buttons !== 0);
  }, []);

  useEffect(() => {
    if (canvasRef.current === null) return;

    let aborted = false;
    let app: Application | undefined;
    let object: SPEObject | undefined;
    let handle: number | undefined;
    let lastTime: number | undefined;

    function frame(time: number) {
      if (app === undefined || object === undefined) return;

      if (lastTime === undefined) {
        setLoaded(true);
        lastTime = time;
      }
      const deltaTime = time - lastTime;

      if (deltaRef.current === 0) object.rotation.y += 0.001 * deltaTime;
      else object.rotation.y += 0.003 * deltaRef.current;
      deltaRef.current = 0;

      app.requestRender();

      lastTime = time;
      handle = requestAnimationFrame(frame);
    }

    async function init() {
      const { Application } = await import("@splinetool/runtime");
      if (aborted || canvasRef.current === null) return;

      app = new Application(canvasRef.current, { renderMode: "manual" });
      await app.load("/xbow-logo-static.splinecode");
      if (aborted) return;

      object = app.findObjectByName("Rotator");
      handle = requestAnimationFrame(frame);
    }

    init().catch(() => {});

    return () => {
      aborted = true;
      if (handle !== undefined) cancelAnimationFrame(handle);
      app?.dispose();
    };
  }, []);

  /*
     Note: If you change h-[xxx] below, you also need to change the size-[xxx]
     on the placeholder at the bottom.

     Note that there are a bunch of custom breakpoints here. These are tuned
     along with SectionHero to push the pass percentages below the fold
     on mobile. The h-[xxx] and size-[xxx] breakpoint values all need to match.
   */

  return (
    <div
      className={clsx(
        "mt-16 md:mt-0 h-[16rem] min-[390px]:h-[21rem] min-[400px]:h-[23rem] min-[415px]:h-[25rem] relative select-none touch-pan-y",
        grabbing ? "cursor-grabbing" : "cursor-grab",
      )}
      onPointerDown={onPointerDown}
      onPointerMove={onPointerMove}
      onPointerUp={onPointerUp}
    >
      <canvas
        ref={canvasRef}
        className="size-full"
        style={{ backgroundColor: "black" }}
      />
      {!loaded && (
        <img
          className="absolute top-0 left-1/2 -translate-x-1/2 size-[16rem] min-[390px]:size-[21rem] min-[400px]:size-[23rem] min-[415px]:size-[25rem] object-cover"
          src="/xbow-logo-placeholder.webp"
          alt="XBOW Logo"
          width="368"
          height="368"
        />
      )}
    </div>
  );
}
